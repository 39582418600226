// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyAok43KY5ogRWaQiZX1ZgbGqK_d7ZVfyRE',
	authDomain: 'sura-lideres-positivos-tv.firebaseapp.com',
	databaseURL: 'https://sura-lideres-positivos-tv-default-rtdb.firebaseio.com',
	projectId: 'sura-lideres-positivos-tv',
	storageBucket: 'sura-lideres-positivos-tv.appspot.com',
	messagingSenderId: '751128715289',
	appId: '1:751128715289:web:364de0c567177713168a88',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app);
