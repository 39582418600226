import { getCollections, getDate, cambiarTextos } from './functions';
let xlsx = require('json-as-xlsx');

export async function reporteUsuarios() {
	let array = [];
	const results = await getCollections('usuarios');
	Object.values(results).map((val) => {
		if (val.mx !== '000000') {
			array.push({
				...val,
				asistencia1: val.asistencia1 ? 'Sí' : 'No',
				asistencia2: val.asistencia2 ? 'Sí' : 'No',
				descarga1: val.descarga1 ? 'Sí' : 'No',
				descarga2: val.descarga2 ? 'Sí' : 'No',
			});
		}
	});
	const fecha = cambiarTextos(getDate());

	let data = [
		{
			sheet: 'Usuarios',
			columns: [
				{ label: 'MX', value: 'mx' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Puesto', value: 'puesto' },
				{ label: 'Gerencia', value: 'gerencia' },
				{ label: 'Dirección', value: 'direccion' },
				{ label: 'Correo', value: 'correo' },
				// { label: 'MasterClass 1', value: 'masterClass1' },
				// { label: 'MasterClass 2', value: 'masterClass2' },
				// { label: 'Clase 1', value: 'clase1' },
				// { label: 'Clase 2', value: 'clase2' },
				// { label: 'Entregable', value: 'entregable' },
				// { label: 'Evaluación final', value: 'evaluacionFinal' },
				{ label: 'Asistencia 1', value: 'asistencia1' },
				{ label: 'Asistencia 2', value: 'asistencia2' },
				{ label: 'Descarga infografía 1', value: 'descarga1' },
				{ label: 'Descarga infografía 2', value: 'descarga2' },
			],
			content: array,
		},
	];
	// console.log(array);

	let settings = {
		fileName: `Asistencias-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}

export async function reporteChats() {
	let array = [];
	const results = await getCollections('chat/sdfdsf800fds8');
	Object.values(results).map((val) => {
		array.push({
			mx: val.user,
			nombre: val.nombre,
			texto: val.text,
			fecha: val.fechaDeRegistro,
		});
	});
	const fecha = cambiarTextos(getDate());

	let data = [
		{
			sheet: 'USuarios',
			columns: [
				{ label: 'MX', value: 'mx' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Mensaje', value: 'texto' },
			],
			content: array,
		},
	];
	// console.log(array);

	let settings = {
		fileName: `Sura-Gestión-Remota-Chats-${fecha}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};

	xlsx(data, settings);
}
