import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { getCollectionsComplete, updateData, getDate } from '../../assets/js/functions';
import logo from '../../assets/images/header/Logo.svg';
import './styles.scss';

export default function Login() {
	const [mx, setMx] = useState('');
	const navigate = useNavigate();

	const validar = async (e) => {
		e.preventDefault();
		const data = await getCollectionsComplete(`${process.env.REACT_APP_BD_USUARIOS}/${mx}`);
		if (mx === '') {
			Swal.fire({
				title: '¡Espera!',
				text: 'Ingresa tu número MX para poder continuar.',
				icon: 'warning',
				confirmButtonText: 'Cerrar',
				confirmButtonColor: '#0033a0',
			});
			return null;
		}
		if (Object.keys(data).length > 0) {
			sessionStorage.setItem('mx', mx);
			updateData(process.env.REACT_APP_BD_USUARIOS, mx, { asistencia2: getDate() })
				.then(() => {
					navigate('/streaming');
				})
				.catch((e) => {
					console.log('Error: ' + e);
				});
		} else {
			setMx('');
			Swal.fire({
				title: '¡Oooopss!',
				text: 'Usuario no registrado.',
				icon: 'error',
				confirmButtonText: 'Cerrar',
				confirmButtonColor: '#0033a0',
			});
		}
	};

	return (
		<>
			<div id='login'>
				<div className='header'>
					<img src={logo} alt='logo' />
				</div>
				<Container>
					<Grid container justifyContent='center' alignItems='center' spacing={4}>
						<Grid item xs={12} sm={12} md={9} lg={9} data-aos='zoom-in' data-aos-delay='0' data-aos-duration='1000'>
							<h1>¡Te damos la bienvenida!</h1>
							<p>
								<span className='azul'>¡Hola!</span> nos alegra tenerte aquí, hemos preparado para ti el programa: <span className='azul'>Gestión remota</span> para fortalecer tus competencias en este ámbito.
							</p>
							<p>
								A continuación, escribe únicamente <span className='azul'>los dígitos</span> de tu MX y disfruta con nosotros el orgullo de ser SURA.
							</p>
						</Grid>
						<Grid item xs={12} sm={12} md={9} lg={9} data-aos='zoom-in' data-aos-delay='500' data-aos-duration='1000'>
							<div className='contenedor'>
								<Grid container justifyContent='center' alignItems='center' spacing={4}>
									<Grid item xs={12} sm={12} md={8} lg={8}>
										<TextField label='MX' variant='filled' fullWidth value={mx} onChange={(e) => setMx(e.target.value)} />
									</Grid>
									<Grid item xs={12} sm={12} md={8} lg={8} className='center'>
										<button onClick={validar}>Ingresar</button>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Grid>
				</Container>
			</div>
		</>
	);
}
