import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import BaseLayout from '../layout/BaseLayout';
import Login from '../views/Login';
import Streaming from '../views/Streaming';
// Administracion
// import Login from "../views/Administracion/Login";
// import ResetPassword from "../views/Administracion/ResetPassword";
// import Perfil from "../views/Administracion/Perfil";
// import Dashboard from "../views/Administracion/Dashboard";
// import Usuarios from "../views/Administracion/Usuarios";
// import UsuariosInactivos from "../views/Administracion/Usuarios/Inactivos";
// import UsuariosCrear from "../views/Administracion/Usuarios/Crear";
// import UsuariosActualizar from "../views/Administracion/Usuarios/Actualizar";
// import Error from "../views/Error";
import ErrorPublica from '../views/ErrorPublica';

export default function Navigation() {
	return (
		<>
			<Router>
				<Routes>
					<Route path='/' element={<Login />} />
					<Route path='/streaming' element={<Streaming />} />
					{/* <Route path='/reestablecer-credenciales' element={<ResetPassword />} /> */}
					<Route path='*' element={<ErrorPublica />} />
					{/* <Route element={<BaseLayout />}>
						<Route path='/admin/dashboard' element={<Dashboard />} />
						<Route path='/admin/configuracion' element={<Perfil />} />
						<Route path='/admin/usuarios' element={<Usuarios />} />
						<Route path='/admin/usuarios/inactivos' element={<UsuariosInactivos />} />
						<Route path='/admin/usuarios/crear' element={<UsuariosCrear />} />
						<Route path='/admin/usuarios/actualizar/:id' element={<UsuariosActualizar />} />
						<Route path='*' element={<Error />} />
					</Route> */}
				</Routes>
			</Router>
		</>
	);
}
