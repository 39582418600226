import React, { useRef, useState, useEffect } from 'react';
import { ref, onChildChanged } from 'firebase/database';
import SendIcon from '@mui/icons-material/Send';
import Swal from 'sweetalert2';
import uniqid from 'uniqid';
import { db } from '../../config/firebase';
import { getCollections, getDate, setData, updateData } from '../../assets/js/functions';
import orderby from 'lodash.orderby';
import Avatar from '../../assets/images/chat/avatar.jpeg';
import Logo from '../../assets/images/chat/apple-icon.png';
import send from '../../assets/images/chat/send.svg';
import './styles.scss';
import BlockIcon from '@mui/icons-material//Block';
import DeleteIcon from '@mui/icons-material//Delete';
import Tooltip from '@mui/material/Tooltip';
const admins = ['000000'];
const NombreAdmin = 'SURA';

export default function Chat(props) {
	const { idConferencia, usuario } = props;

	return (
		<div id='chat'>
			<div className='App'>
				<section>
					<div className='titulo-chat'>
						<div>Chat</div>
					</div>
					<ChatRoom idConferencia={idConferencia} />
				</section>
			</div>
		</div>
	);
}

function ChatRoom(props) {
	const { idConferencia } = props;
	let scroll_to_bottom = document.getElementById('main');

	const [formValue, setFormValue] = useState('');
	const [messages, setMessages] = useState([]);
	const dummy = useRef();

	const vetarUsuario = async (idUser) => {
		Swal.fire({
			title: '¿Deseas borrar mensajes de este usuario?',
			icon: 'warning',
			confirmButtonText: 'Confirmar',
			confirmButtonColor: '#c1212a',
			showCancelButton: true,
			cancelButtonText: 'cerrar',
		}).then(async (result) => {
			if (result.isConfirmed) {
				const array = [];
				const json = {};
				const dataMessages = await getCollections(`${process.env.REACT_APP_BD_CHATS}/${idConferencia}`);
				Object.values(dataMessages).map((val) => {
					if (val.user === idUser) {
						array.push({ ...val, visible: false });
						json[val.id] = { ...val, visible: false };
					} else {
						array.push(val);
						json[val.id] = { ...val };
					}
				});
				const order = orderby(array, ['idIncrement'], ['asc']);
				setData('chat', 'b76h9215l31otamc', json);
				setMessages(order);
			}
		});
	};

	useEffect(() => {
		getMessages();
		const dbRef = ref(db, `${process.env.REACT_APP_BD_CHATS}/`);
		onChildChanged(dbRef, (data) => {
			getMessages();
		});
	}, []);

	useEffect(() => {
		// 👇️ scroll to bottom every time messages change
		dummy.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
		// var objDiv = document.getElementById("main");
		// objDiv.scrollTop = objDiv.scrollHeight;
		let scroll_to_bottom = document.getElementById('main');
		scrollBottom(scroll_to_bottom);
	}, [messages]);
	function scrollBottom(element) {
		// console.log(element)
		element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
	}

	const getMessages = async (e) => {
		const dataMessages = await getCollections(`${process.env.REACT_APP_BD_CHATS}/${idConferencia}`);
		const order = orderby(dataMessages, ['idIncrement'], ['asc']);
		setMessages(order);
		// dummy.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
	};

	const sendMessage = async (e) => {
		e.preventDefault();
		const dataUser = await getCollections(`${process.env.REACT_APP_BD_USUARIOS}`);
		const infoUser = dataUser.filter((val) => val.mx === sessionStorage.getItem('mx'));
		const id = uniqid();
		let json = {
			id,
			idIncrement: messages.length + 1,
			text: formValue,
			visible: true,
			fechaDeRegistro: getDate(),
			user: sessionStorage.getItem('mx'),
			nombre: `${infoUser[0].nombre}`,
		};
		setMessages([...messages, json]);
		setData(`${process.env.REACT_APP_BD_CHATS}/${idConferencia}`, id, json);
		setFormValue('');
	};

	return (
		<>
			<main id='main'>
				{messages && messages.map((msg) => <ChatMessage key={msg.id} message={msg} vetarUsuario={vetarUsuario} idConferencia={idConferencia} />)}
				<div ref={dummy}></div>
			</main>

			<form onSubmit={sendMessage}>
				<input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder='Escribe un mensaje...' />
				<button type='submit' disabled={!formValue}>
					{/* <img src={send} alt='send' className='full' /> */}
					<SendIcon />
				</button>
			</form>
		</>
	);
}

function ChatMessage(props) {
	const { text, user, fechaDeRegistro, nombre, visible, id, idIncrement } = props.message;
	const { vetarUsuario, idConferencia } = props;

	let nombreUser = nombre;
	let imgMessage = Avatar;
	Object.values(admins).map((val) => {
		if (val === user) {
			nombreUser = NombreAdmin;
			imgMessage = Logo;
		}
	});

	const vetarMessage = (idMessage) => {
		let json = {
			fechaDeRegistro,
			id,
			idIncrement,
			nombre,
			text,
			user,
			visible: !visible,
		};
		Swal.fire({
			title: '¿Deseas borrar el mensaje?',
			icon: 'warning',
			confirmButtonText: 'Confirmar',
			confirmButtonColor: '#c1212a',
			showCancelButton: true,
			cancelButtonText: 'cerrar',
		}).then((result) => {
			if (result.isConfirmed) {
				updateData(`${process.env.REACT_APP_BD_CHATS}/${idConferencia}`, idMessage, json);
			}
		});
	};

	const a = () => {
		let display = 'received';

		if (admins.indexOf(user) >= 0) {
			display = 'sent';
		} else {
			display = 'received';
		}

		return display;
	};

	const messageClass = a();

	const displayMessage = () => {
		let display = 'none';

		if (admins.indexOf(user) >= 0) {
			display = 'block';
		} else {
			display = 'none';
		}

		return display;
	};

	const displayVetado = () => {
		let clase = '';
		if (admins.indexOf(user) >= 0 && !visible) {
			clase = 'vetado';
		}
		return clase;
	};

	return (
		<>
			<div className={displayVetado()} style={{ display: `${visible ? 'block' : displayMessage()}` }}>
				<span className={`${messageClass} fecha blanco`}>{fechaDeRegistro}</span>
				<div className={`message ${messageClass}`}>
					<img className='hijos' src={imgMessage} alt='as' />
					<p>
						<span className='bold'>
							{nombreUser}
							<br />
						</span>
						{text}
					</p>
					{admins.indexOf(user) >= 0 && (
						<>
							<div className='cursor' onClick={() => vetarMessage(id)}>
								<Tooltip title='Borrar mensaje' placement='bottom-start'>
									<DeleteIcon className='blanco' />
								</Tooltip>
							</div>
							{/* <div className='cursor' onClick={() => vetarUsuario(user)}>
                        <Tooltip title="Bloquear usuario" placement="bottom-start">
                            <BlockIcon />
                        </Tooltip>
                    </div> */}
						</>
					)}
				</div>
			</div>
		</>
	);
}
