export default function ValidateUser({ children }) {
	const id = sessionStorage.getItem('mx');
	let content = '';

	if (id !== null) {
		content = <div>{children}</div>;
	} else {
		window.location.replace('/');
	}
	return content;
}
